import React from 'react';
import CurriculumSelector from '../../components/CurriculumSelector';
import DigitalLessonBundle from '../../components/DigitalLessonBundle';
import { Column, Container, Row } from '../../components/Grid';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import Resource from '../../components/Resource';
import Section from '../../components/Section';
import Video from '../../components/Video';
import Action from '../../components/Resource/Action';

const IndexPage = ({ data }) => (
  <Layout title="Conservation of Energy and Energy Transfer">
    <Section>
      <Container>
        <Row>
          <Column size={11}>
            <h1 className="page-header">
              CONSERVATION OF ENERGY AND<br></br>ENERGY TRANSFER
            </h1>
          </Column>
        </Row>
        <Row>
          <Column size={8}>
            <Video
              poster="conservationofenergy-image-videostill@2x.jpg"
              guidSrc="9ae0df93-6b94-4fc1-9349-3fd68a118a2d"
              title="The Relationship Between Energy and Forces"
            />
          </Column>
          <Column size={4}>
            <p>
              Discover how energy transformation (often multiple conversions to
              different forms or between potential and kinetic energy) powers
              the world around us, our homes, our devices, and ourselves.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** VTS GUIDE **************/}
    <Section>
      <Container>
        <Row className="educator-guide-forces">
          <Column size={4}>
            <Image filename="resources-image-EdGuide.png" />
          </Column>
          <Column size={6} className="educator-guide-column">
            <h3>Video Topic Series Guide</h3>
            <p>
              The Caterpillar Foundation Video Series Guide for high school
              physics is a standards-aligned curriculum of engaging videos and
              hands-on activities designed to bring the world of STEM and
              Industry 4.0 into the classroom. Students learn the fundamentals
              of physical laws and how to problem solve by tackling real-world
              challenges in class and after-school sessions. Through a process
              of investigation, team discussion, and hands-on experiences,
              students will advance theory into applications they can see and
              touch.
            </p>
          </Column>
          <Column size={2} className="bottom-button">
            <div className="resource__actions resource__actions--aligned">
              <Action
                label="Download"
                dropdown={true}
                dropdownItemClassname="vts__dropdown-items"
                actions={[
                  {
                    label: 'English',
                    download: '/pdfs/Caterpillar-VTS-EdGuide.pdf',
                    metadata: '.PDF, 410KB',
                  },
                  {
                    label: 'Spanish',
                    download: '/pdfs/Caterpillar-VTS-EdGuide_ES.pdf',
                    metadata: '.PDF, 418KB',
                  },
                  {
                    label: 'French',
                    download: '/pdfs/Caterpillar-VTS-EdGuide_FR.pdf',
                    metadata: '.PDF, 436KB',
                  },
                ]}
              />
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** VTS ACTIVITIES **************/}
    <Section>
      <Container>
        <Row>
          <Column size={12}>
            <h2>Hands-On Classroom Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={9}>
            <p>
              Discover how the Laws of Motion play a part in every single thing
              we do with these simple, easy-to-implement activations aligned to
              education standards. Students will take on a series of creative
              STEM challenges in which they put the power of force and motion
              into action.
            </p>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <Resource
              img={'resources-conservationofenergy-image-activity1@2x.png'}
              subject={'Classroom Activity'}
              title={'Eco-Friendly Infrastructure'}
              audience="Is there a more eco-friendly way for people to move around their cities and towns?"
              description="Students learn about energy efficiency and conservation and transforming potential energy to kinetic. Then they design an eco-friendly alternative to personal cars—energy-efficient tramways that make it seem like people are flying to their destination!"
              actions={[
                {
                  label: 'Download',
                  dropdown: true,
                  actions: [
                    {
                      label: 'English',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-EcoFriendlyInfrastructure.pdf',
                      metadata: '.PDF, 574KB',
                    },
                    {
                      label: 'Spanish',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-EcoFriendlyInfrastructure-ES.pdf',
                      metadata: '.PDF, 706KB',
                    },
                    {
                      label: 'French',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-EcoFriendlyInfrastructure-FR.pdf',
                      metadata: '.PDF, 728KB',
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              img={'resources-conservationofenergy-image-activity2@2x.png'}
              subject={'Classroom Activity'}
              title={'Ergo-lifting'}
              audience="How can physically demanding jobs be made safer for workers?"
              description="Physically demanding careers don’t have to be physically debilitating. The science of ergonomics helps improve how we move and lift objects safely and efficiently. Students then learn about energy transformation—from potential to kinetic—by physically lifting a box and analyzing what’s happening."
              actions={[
                {
                  label: 'Download',
                  dropdown: true,
                  actions: [
                    {
                      label: 'English',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-ErgoLifting.pdf',
                      metadata: '.PDF, 663KB',
                    },
                    {
                      label: 'Spanish',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-ErgoLifting-ES.pdf',
                      metadata: '.PDF, 810KB',
                    },
                    {
                      label: 'French',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-ErgoLifting-FR.pdf',
                      metadata: '.PDF, 829KB',
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              img={'resources-conservationofenergy-image-activity3@2x.png'}
              subject={'Classroom Activity'}
              title={'Exoskeleton Solutions'}
              audience="Have you ever wondered how the energy in the food we eat is converted to power our muscles?"
              description="Just like machines and computers, muscles need energy to work. Students examine the role of ATP to store and transfer energy within cells and then design an exoskeleton to help humans perform a physical task more efficiently."
              actions={[
                {
                  label: 'Download',
                  dropdown: true,
                  actions: [
                    {
                      label: 'English',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-Exo-SkeletonSolutions.pdf',
                      metadata: '.PDF, 551KB',
                    },
                    {
                      label: 'Spanish',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-Exo-SkeletonSolutions-ES.pdf',
                      metadata: '.PDF, 633KB',
                    },
                    {
                      label: 'French',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-Exo-SkeletonSolutions-FR.pdf',
                      metadata: '.PDF, 660KB',
                    },
                  ],
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** QUOTE **************/}
    <Section>
      <Container>
        <Row>
          <Column size="12" className="forces-quote">
            <Image
              filename="resources-graphic-quotemark.svg"
              className="start-slash"
            />
            <p className="forces-quote-text">
              AI, big data, machine learning, and automation will usher in a new
              and novel world full of interesting careers that don’t even exist
              today.
            </p>
            <Image
              filename="resources-graphic-quotemark.svg"
              className="end-slash"
            />
            <p className="forces-quote-author">– Kat, Host</p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** AFTERSCHOOL ACTIVITIES **************/}
    <Section>
      <Container>
        <Row className="section-image-header">
          <Column size={6}>
            <h2>After School Activity</h2>
          </Column>
          <Column size={6}>
            <Image filename="resources-graphic-lineart-ASactivity.svg" />
          </Column>
        </Row>
        <Row>
          <Column size={9}>
            <p>
              Explore the ways that the Laws of Motion can be harnessed in
              students’ everyday lives with a series of engaging activities that
              are easy to implement in any environment.
            </p>
          </Column>
        </Row>
        <Row>
          <DigitalLessonBundle
            resource1={
              <Resource
                img={'resources-conservationofenergy-image-ASactivity@2x.png'}
                subject={'Afterschool Activity'}
                title={'Pocket Change to Power'}
                className="magnus-effect"
                description="With the increased energy demands of Industry 4.0, battery capacity and efficiency are more important than ever. Students will learn the components of a battery and will construct a basic battery to power an LED using pocket change and other household items."
                actions={[
                  {
                    label: 'Download',
                    dropdown: true,
                    actions: [
                      {
                        label: 'English',
                        download:
                          '/pdfs/Caterpillar-AfterschoolActivity-PocketChange.pdf',
                        metadata: '.PDF, 612KB',
                      },
                      {
                        label: 'Spanish',
                        download:
                          '/pdfs/Caterpillar-AfterschoolActivity-PocketChange-ES.pdf',
                        metadata: '.PDF, 775KB',
                      },
                      {
                        label: 'French',
                        download:
                          '/pdfs/Caterpillar-AfterschoolActivity-PocketChange-FR.pdf',
                        metadata: '.PDF, 800KB',
                      },
                    ],
                  },
                ]}
              />
            }
            resource2={
              <Resource
                img={'global-image-ASFacilitatorguide@2x.png'}
                title={'Facilitator Guide'}
                className="facilitator-guide"
                description="Students learn the fundamentals of physics and how to solve problems by tackling real-world challenges in out of class and after-school sessions. Through a process of investigation, team discussion, and hands-on experiences, students will advance theory into applications they can see and touch."
                actions={[
                  {
                    label: 'Download',
                    dropdown: true,
                    actions: [
                      {
                        label: 'English',
                        download: '/pdfs/Caterpillar-AfterschoolGuide.pdf',
                        metadata: '.PDF, 606KB',
                      },
                      {
                        label: 'Spanish',
                        download: '/pdfs/Caterpillar-AfterschoolGuide-ES.pdf',
                        metadata: '.PDF, 718KB',
                      },
                      {
                        label: 'French',
                        download: '/pdfs/Caterpillar-AfterschoolGuide-FR.pdf',
                        metadata: '.PDF, 736KB',
                      },
                    ],
                  },
                ]}
              />
            }
            className="dlb-row"
          />
        </Row>
      </Container>
    </Section>
    {/************** CURRICULUM SELECTOR **************/}
    <Section>
      <Container>
        <CurriculumSelector />
      </Container>
    </Section>
    {/************** TESTIMONIAL **************/}
    <Section>
      <Container>
        <Row>
          <Column>
            <Image filename="resources-image-testimonial.png" />
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default IndexPage;
